import { Dispatch, FC, SetStateAction } from "react";
import { Dayjs } from "dayjs";
import Day from '../../../Day/Day'
import Today from '../../../Today/Today'
import { WorkoutItem } from "../../../../../types";
import styled from "@emotion/styled";
import { getCellIdByDate } from "../../../../utils/getCellIdByDate";

interface IProps extends ICellProps {
  item: WorkoutItem;
  isCurrentMonth: boolean;
  id: string;
  activeCardId: string | undefined;
  onSetActiveCardId: Dispatch<SetStateAction<string | undefined>>
  onRemoveActiveCardId: VoidFunction;
}

export interface ICellProps {
  onCalendarClick: (date: Dayjs) => void;
}

export const Cell: FC<IProps> = ({
  item,
  onCalendarClick,
  isCurrentMonth,
  id,
  activeCardId,
  onSetActiveCardId,
  onRemoveActiveCardId,
}) => {
  return (
    <SWrapper
      id={'cell-' + getCellIdByDate(item.date.toDate())}
      className='cell'
      onClick={(e) => {
        e.stopPropagation()
        onCalendarClick(item.date)
      }}
      isCurrentMonth={isCurrentMonth}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "flex-start",
          flexDirection: "column",
          padding: 8,
        }}
      >
        {item.isCurrentDay ? (
          <Today 
            id={id}
            item={item} 
            activeCardId={activeCardId}
            onSetActiveCardId={onSetActiveCardId}
            onRemoveActiveCardId={onRemoveActiveCardId}
          />
        ) : (
          <Day 
            id={id}
            item={item} 
            activeCardId={activeCardId}
            onSetActiveCardId={onSetActiveCardId}
            onRemoveActiveCardId={onRemoveActiveCardId}
          />
        )}
      </div>
    </SWrapper>
  );
}

const SWrapper = styled.div<{ isCurrentMonth: boolean }>`
  width: 100%;
  min-height: 232px;
  height: 100%;
  vertical-align: top;
  padding: 0;
  transition: background 0.3s;
  user-select: none;

  ${({ isCurrentMonth }) => !isCurrentMonth && `
    & span {
      color: #97A4B7;
    }
  `}

  &:hover {
    background: #F8FAFC;
    cursor: pointer;
  }
`

