import {authStyles} from "../../../../styles/authStyles";
import {useAppContext} from "../../../../AppContext";
import {useFormik} from "formik";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { ExternalLoginButtons } from "../../../../components/login/ExternalLoginButtons";
import clsx from "clsx";
import PasswordTextField from "../../../../components/common/PasswordTextField";
import LinkWithRedirect from "../../../../components/common/LinkWithRedirect";
import { useTranslation } from "react-i18next";
import styled from '@emotion/styled'
import { UserRole } from "../../../../apis/AuthApi";

export const RegisterForm = () => {
  const { classes } = authStyles();
  const { api } = useAppContext();

  const { t } = useTranslation()
  let navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      password: "",
      accept: false,
    },
    onSubmit: async (values) => {
      const isAthlete = params.has("redirect") ? params.get("redirect")!.indexOf("/addmetocoach") > -1 : false;
      const result = await api.authApi.register({
        fullName: values.fullName,
        email: values.email,
        password: values.password,
        consent: values.accept,
        role: isAthlete ? UserRole.Athlete : null,
      });
      if (result.success) {
        if (params.has("redirect")) {
          const redirectUrl = params.get("redirect");
          navigate(redirectUrl || "/dashboard");
        } else {
          navigate("/dashboard");
        }
      }
    },
  });

  return (
    <SWrapper>
      <Typography variant="h4" className={classes.header}>
        {t('registration')}
      </Typography>
      <Typography variant="h5" className={classes.headerSubItem}>
        {t('createAccountVia')}
      </Typography>
      <ExternalLoginButtons classes={classes} />
      <Typography className={clsx(classes.orLabel, classes.headerSubItem)}>
        - {t('or')} -
      </Typography>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <Grid container>
          <Grid item xs={12}>
            <TextField
              className="yna"
              autoComplete="off"
              type="text"
              name="fullName"
              label={t('nameInput')}
              variant="outlined"
              fullWidth
              value={formik.values.fullName}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="yna"
              autoComplete="off"
              type="email"
              style={{ marginTop: "37px" }}
              name="email"
              label={t('emailInput')}
              variant="outlined"
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordTextField
              className="yna"
              autoComplete="off"
              style={{ marginTop: "37px" }}
              name="password"
              label={t('passwordInput')}
              variant="outlined"
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container display={"flex"} alignContent={"center"}>
              <Grid item>
                <FormControlLabel
                  name="accept"
                  control={<Checkbox size="small" />}
                  style={{ marginTop: "37px" }}
                  label={
                    <>
                      {t('agree')}{" "}
                      <span>
                        <Link
                          to={t('terms')}
                          target="_blank"
                          className={classes.loginPageLink}
                        >
                          <Typography component="span">
                            {t('termsLabel')}
                          </Typography>
                        </Link>
                        &nbsp;{t('and')}&nbsp;
                        <Link
                          to={t('privacyPolicyUrl')}
                          target="_blank"
                          className={classes.loginPageLink}
                        >
                          <Typography component="span">
                            {t('privacyPolicyLabel')}
                          </Typography>
                        </Link>
                      </span>
                    </>
                  }
                  checked={formik.values.accept}
                  value={formik.values.accept}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.submitWrapper}>
          <Button
            className="yna"
            type="submit"
            variant="contained"
            color="primary"
            disabled={!formik.values.accept}
            fullWidth
          >
            {t('createAccountButton')}
          </Button>
        </Grid>
      </form>
      <Grid
        container
        flexWrap={"wrap"}
        justifyContent={"center"}
        className={classes.registrationWrapper}
      >
        <Grid item display={"flex"} alignContent={"center"}>
          <Typography className="register-prompt" variant="body1">
            {t('alreadyHaveAccount')}
          </Typography>
        </Grid>
        <Grid item flexWrap={"wrap"} display={"flex"} alignContent={"center"}>
          <LinkWithRedirect to={"/"} className={classes.loginPageLink}>
            <Typography component="span">&nbsp;{t('alreadyHaveLogin')}</Typography>
          </LinkWithRedirect>
        </Grid>
      </Grid>
      <Grid item flexWrap={"wrap"} display={"flex"} alignContent={"center"} justifyContent={"center"} marginTop={6}>
        <Typography component="span">© 2023 YNA LLC. All rights reserved</Typography>
      </Grid>
    </SWrapper>
  );
};

const SWrapper = styled.div`
  box-sizing: border-box;
  padding: 20px 0;
`