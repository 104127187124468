import styled from '@emotion/styled';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from 'react';
import { ExternalLoginButtons } from "../../../../components/login/ExternalLoginButtons";
import clsx from "clsx";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../../../../AppContext";
import { useFormik } from "formik";
import PasswordTextField from "../../../../components/common/PasswordTextField";
import { authStyles } from "../../../../styles/authStyles";
import LinkWithRedirect from "../../../../components/common/LinkWithRedirect";
import { useTranslation } from "react-i18next";
import { useErrorBoundary } from 'react-error-boundary';

export interface LoginFormProps { }

export const LoginForm = (props: LoginFormProps) => {
  const { classes } = authStyles();
  const { api } = useAppContext();
  const { t } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { showBoundary } = useErrorBoundary();
  const formik = useFormik({
    initialValues: {
      email: "",
      pwd: "",
      rememberMe: false,
    },
    onSubmit: async (values) => {
      void api.authApi.auth({
        login: values.email,
        password: values.pwd,
        rememberMe: values.rememberMe,
      })
        .then((result) => {
          if (result.success) {
            if (params.has("redirect")) {
              const redirectUrl = params.get("redirect");
              navigate(redirectUrl || "/dashboard");
            } else {
              navigate("/dashboard");
            }
          }
          else {
            setErrorMessage(result.errors?.[0] || 'Ошибка при получении ответа от сервера');
          }
        })
        .catch((e) => {
          if (e?.response?.data?.errors && Object.keys(e.response.data?.errors)?.length) {
            setErrorMessage(Object.values(e.response.data.errors).join('\n'));
            return;
          }

          if (e.response.status === 400) {
            setErrorMessage(e.response?.data?.title || e.response?.data || e.message);
            return;
          }

          showBoundary(e)
        })
    },
  });

  let navigate = useNavigate();

  return (
    <SWrapper>
      <Typography variant="h4" className={classes.header}>
        {t('authorization')}
      </Typography>
      <Typography variant="h5" className={classes.headerSubItem}>
        {t('loginVia')}
      </Typography>
      <ExternalLoginButtons classes={classes} />
      <Typography className={clsx(classes.orLabel, classes.headerSubItem)}>
        - {t('or')} -
      </Typography>
      {errorMessage && (
        <Grid paddingY={3}>
          <Typography color="#ff0030">{errorMessage}</Typography>
        </Grid>
      )}
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <Grid container>
          <Grid item xs={12}>
            <TextField
              required
              name="email"
              className="yna"
              autoComplete="off"
              label={t('emailInput')}
              variant="outlined"
              value={formik.values.email}
              fullWidth
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordTextField
              required
              name="pwd"
              className="yna"
              autoComplete="off"
              style={{ marginTop: "37px" }}
              label={t('passwordInput')}
              variant="outlined"
              fullWidth
              value={formik.values.pwd}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
        <Grid
          className={classes.rememberWrapper}
          container
          justifyContent={"space-between"}
        >
          <Grid item>
            <FormControlLabel
              name="rememberMe"
              label={t('rememberMe')}
              control={<Checkbox size="small" />}
              checked={formik.values.rememberMe}
              value={formik.values.rememberMe}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item display={"flex"} alignContent={"center"}>
            <Link to="/login/reset-password" className={classes.loginPageLink}>
              <Typography component="span">{t('forgotPassword')}</Typography>
            </Link>
          </Grid>
        </Grid>
        <Grid container className={classes.submitWrapper}>
          <Button
            className="yna"
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
          >
            {t('login')}
          </Button>
        </Grid>
        <Grid
          container
          flexWrap={"wrap"}
          justifyContent={"center"}
          className={classes.registrationWrapper}
        >
          <Grid item display={"flex"} alignContent={"center"}>
            <Typography className="register-prompt" variant="body1">
              {t('notRegistered')}
            </Typography>
          </Grid>
          <Grid item flexWrap={"wrap"} display={"flex"} alignContent={"center"}>
            <LinkWithRedirect
              to={"/login/register"}
              className={classes.loginPageLink}
            >
              <Typography component="span">&nbsp;{t('notRegisteredCreateAccount')}</Typography>
            </LinkWithRedirect>
          </Grid>
        </Grid>
      </form>
      <Grid item flexWrap={"wrap"} display={"flex"} alignContent={"center"} justifyContent={"center"} marginTop={6}>
        <Typography component="span">© 2023 YNA LLC. All rights reserved</Typography>
      </Grid>
    </SWrapper>
  );
};

const SWrapper = styled.div`
  box-sizing: border-box;
  padding: 20px 0;
`
