import styled from "@emotion/styled";
import { Popover } from "@mui/material";
import { Dispatch, FC, MouseEvent, useState, useEffect, useRef } from "react";
import { CustomWorkoutCard } from "./components/CustomWorkoutCard/CustomWorkoutCard";
import { WorkoutData } from "../../../../../stores/WorkoutStore";
import WorkoutInfo from "../../../workout/display";
import { Draggable } from "../../../../common/Draggable/Draggable";
import { observer } from "mobx-react";
import { insertUrlParam } from "../../utils/insertUrlParam";

interface IProps {
  onClose: VoidFunction;
  onClick: Dispatch<MouseEvent<HTMLDivElement>>;
  workout: WorkoutData;
  onEdit: (e: any, item: WorkoutData) => void;
  onDelete: (id: string | null | undefined) => void;
  onUnmerge: (id: string | null | undefined) => void;
  onMerge: (id: string | null | undefined) => void;
  todayEvents: WorkoutData[];
  isActive: boolean;
}

export const WorkoutCard: FC<IProps> = observer(
  ({
    onClose,
    onClick,
    workout,
    onEdit,
    onDelete,
    isActive,
    onUnmerge,
    onMerge,
    todayEvents,
  }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [anchor, setAnchor] = useState<HTMLDivElement>();

    useEffect(() => {
      if (!ref.current) {
        return;
      }

      if (isActive) {
        setAnchor(ref.current);
        return;
      }

      setAnchor(undefined);
    }, [isActive]);

    if (!workout.id) {
      return null;
    }

    const closeHandle = (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();
      onClose();
      setAnchor(undefined);
    };

    const clickHandle = (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();
      onClick(e);
      insertUrlParam('day', (e.currentTarget as any).closest('.cell')?.id)
    };

    return (
      <SWrapper ref={ref}>
        <Draggable id={workout.id}>
          <SCard onClick={clickHandle}>
            <CustomWorkoutCard item={workout} />
          </SCard>
        </Draggable>
        <Popover
          open={isActive && !!anchor}
          onClose={closeHandle}
          anchorEl={anchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <WorkoutInfo
            onClose={closeHandle}
            item={workout}
            onEdit={(e) => onEdit(e, workout)}
            onDelete={() => {
              onDelete(workout.id);
            }}
            onMerge={() => {
              onMerge(workout.id);
            }}
            onUnmerge={() => {
              onUnmerge(workout.id);
            }}
            todayEvents={todayEvents}
          />
        </Popover>
      </SWrapper>
    );
  }
);

const SCard = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
`

const SWrapper = styled.div`
  width: 100%;
`
