import { css, FormLabel } from "@mui/material";
import { DateValidationError } from '@mui/x-date-pickers';
import { FieldChangeHandler } from '@mui/x-date-pickers/internals';
import { FC, ReactNode } from "react";
import styled from "@emotion/styled";

export interface IBaseInputProps {
  label?: string;
  className?: string;
  fullWidth?: boolean;
  value?: any;
}

interface IProps extends IBaseInputProps {
  as: FC<any>;
  slots?: Record<string, unknown>;
  children?: ReactNode;
  units?: string;
  icon?: ReactNode;
}

export const BaseInput: FC<IProps> = ({
  as,
  children,
  label,
  className,
  fullWidth,
  units,
  icon,
  ...props
}) => {
  const Component = as;
  return (
    <SWrapper className={className} fullWidth={fullWidth}>
      {(!!label || !!units) && (
        <STopWrapper>
          {!!label ? (
            <SLabel>
              <FormLabel component="legend">{label}</FormLabel>
            </SLabel>
          ) : <div />}
          {!!units ? (
            <SUnits>
              {units}
            </SUnits>
          ) : <div />}
        </STopWrapper>
      )}
      <SInput fullWidth={fullWidth} hasLabel={!!label}>
        <Component
          size="small"
          variant="outlined"
          {...props}
          value={props.value === undefined ? null : props.value}
        >
          {children}
        </Component>
        {!!icon && <SIcon>{icon}</SIcon>}
      </SInput>
    </SWrapper>
  )
}

const SInput = styled.div<{ fullWidth?: boolean; hasLabel: boolean }>`
  position: relative;
  min-height: 36px;
  width: 100%;
  background: #EEF5FB;

  ${({ hasLabel }) => hasLabel && css`
    margin-top: 5px;
  `}

  & > div {
    ${({ fullWidth }) => fullWidth && css`
      width: 100%;
    `}
  }

  & div {
    background: none;
    background-color: transparent;
  }

  & fieldset {
    border: 1px solid #CDD9EA;
  }

  & div fieldset {
    border-color: #97A4B7!important;
  }

  & input {
    height: 36px;
    box-sizing: border-box;
    color: #1A222D;
    background: #EEF5FB;
    overflow: hidden;
    border-radius: 5px;

    &::placeholder {
      color: #7B8A9E;
    }
  }

  & > div > div:has(textarea) {
    color: #1A222D;
    background: #EEF5FB;
    border-color: #CDD9EA;

    &::placeholder {
      color: #7B8A9E;
    }
  }
`

const SIcon = styled.span`
  display: flex;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
`

const SWrapper = styled.div<{ fullWidth?: boolean }>`
  ${({ fullWidth }) => fullWidth && css`
    width: 100%;
  `}
  ${({ fullWidth }) => !fullWidth && css`
    width: 280px;
    max-width: 280px;
  `}
`

const SLabel = styled.div`
  & legend {
    font-size: 14px;
    font-weight: 500;
    color: #1A222D;
  }
`

const SUnits = styled.div`
  color: #7B8A9E;
  font-size: 10px;
  line-height: 12px;
`

const STopWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`
