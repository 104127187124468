import { Box, Button, Modal } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close.svg";
import styled from "@emotion/styled";

export default ({
  open,
  onClose,
  onConfirm,
  children,
  okButtonText,
  title,
  hideOkButton = false,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  children?: React.ReactNode;
  okButtonText: string;
  title: string | null;
  hideOkButton?: boolean;
}) => {
  return (
    <SModal
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={(e) => e.stopPropagation()}
      open={open}
      onClose={onClose}
    >
      <SContainer>
        <STopDiv>
          {title || <div></div>}
          <SCloseIcon
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
          >
            <CloseIcon />
          </SCloseIcon>
        </STopDiv>

        <STextContent>
          <SContentContainer>{children}</SContentContainer>

          <SButtonContainer>
            <SButton
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            >
              Отмена
            </SButton>
            {!hideOkButton && (
              <SActionButton
                onClick={(e) => {
                  e.stopPropagation();
                  onConfirm();
                }}
              >
                {okButtonText}
              </SActionButton>
            )}
          </SButtonContainer>
        </STextContent>
      </SContainer>
    </SModal>
  );
};

const SModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const STopDiv = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  backgroundColor: "#FFF",
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  borderBottom: "1px solid var(--G4-line-Main, #CDD9EA)",
  paddingLeft: "26px",
  alignItems: "center",
  color: "#1A222D",
  fontSize: "16px",
  fontWeight: "400",
});

const STextContent = styled(Box)({
  padding: "24px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const SContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  backgroundColor: "#FFF",
  borderRadius: "8px",
});

const SCloseIcon = styled.div`
  cursor: pointer;
  padding: 2px;
  justify-content: end;
  display: flex;
`;

const SContentContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  display: flex;
  padding: 10px;
  gap: 10px;
  width: 370px;
`;

const SButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 18px;
  height: 58px;
  column-gap: 20px;
  justify-content: center;
`;

const SButton = styled(Button)`
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--Black, #1a222d);
  border-radius: 6px;
  background: var(--G2, #eef5fb);
  text-transform: none;
`;
const SActionButton = styled(Button)`
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  color: var(--white, #fff);
  border-radius: 6px;
  background: var(--red-red, #ff3147);

  text-align: center;
  /* Txt 14 Medium */
  font-family: Golos Text;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  text-transform: none;
`;
