import { css } from "@emotion/react";
import styled from "@emotion/styled"
import { FC, useRef } from "react";
import { useHoverWidth } from "../ChartData/components/Graph/hooks/useHoverWidth";
import { getGreenHoverCss } from "../ChartData/utils/getGreenHoverCss";

interface IProps {
  positionPercent: number | undefined;
  onMouseMove: (percent: number) => void;
  onMouseOver: VoidFunction;
  steps: number[];
  activeStep: number | undefined
  onClick: VoidFunction;
}

export const Stepper: FC<IProps> = ({
  positionPercent,
  onMouseMove,
  onMouseOver,
  steps,
  activeStep,
  onClick,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const end = steps[steps.length - 1];
  const isLastStepLowThanOneHalfStep = end - steps[steps.length - 2] < 0.5;
  const innerDiv = contentRef.current?.querySelector('div')
  const innerWidth = innerDiv ? innerDiv.clientWidth - 16 : 0;

  const {
    hoverWidth,
    ref,
  } = useHoverWidth();

  return (
    <SWrapper
      ref={ref}
      hoverWidth={hoverWidth}
      positionPercent={positionPercent}
      onMouseMove={(e) => {
        if (!innerWidth) {
          return;
        }
        const div = contentRef.current?.querySelector('div')
        if (!div) {return;}

        const mouseX = div.getBoundingClientRect().left - e.clientX + 32;
        e.stopPropagation();
        e.preventDefault()

        let percent = Math.abs(mouseX / (innerWidth - 32));

        onMouseMove(percent)
      }}
      onMouseLeave={onMouseOver}
      onClick={onClick}
    >
      <SContent ref={contentRef}>
        <SInnerContent>
          <STriangle positionPercent={positionPercent} />
          <SFirstStep />
          {steps.map((step, idx) => (
            <SStep
              key={step}
              isActive={activeStep === step}
              isPrevLastStep={idx === steps.length - 2}
              isLastStep={idx === steps.length - 1}
              isLastStepLowThanOneHalfStep={isLastStepLowThanOneHalfStep}
              position={innerWidth ? innerWidth * (step / end) : 0}
            >
              {step}
            </SStep>
          ))}
        </SInnerContent>
      </SContent>
      <SLine />
    </SWrapper >
  )
}

const SWrapper = styled.div<{
  positionPercent: number | undefined;
  hoverWidth: number | undefined;
}>`
  position: relative;
  background: #fff;
  display: flex;
  border: 1px solid #CDD9EA;
  box-sizing: border-box;
  overflow: hidden;

  ${getGreenHoverCss(true) as any}
`

const STriangle = styled.div<{ positionPercent: number | undefined }>(({ positionPercent }) => positionPercent && `
  position: absolute;
  top: calc(50% + 1px);
  z-index: 2;
  margin-left: calc(${positionPercent * 100}% - 8px);
  border-top: 10px solid #7B8A9E;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
`)

const SStep = styled.div<{
  isActive: boolean;
  position: number;
  isLastStep: boolean;
  isPrevLastStep: boolean;
  isLastStepLowThanOneHalfStep: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border: 2px solid #B7C6DA;
  background: #fff;
  border-radius: 100px;
  padding: 2px 7px;
  margin-top: -10px;
  position: absolute;

  ${({ position, isPrevLastStep, isLastStep, isLastStepLowThanOneHalfStep }) => () => {
    // if (isLastStepLowThanOneHalfStep && isPrevLastStep) {
    //   return css`left: ${position - 60}px;`;
    // }

    if (isLastStepLowThanOneHalfStep && isLastStep) {
      return css`display: none`;
    }

    return css`left: ${position}px;`;
  }
  }

  ${({ isActive }) => isActive && css`
    background: #3C415B;
    border: 2px solid #3C415B;
    color: #fff;
    z-index: 2;
  `}
`

const SContent = styled.div`
  width: 100%;
  z-index: 2;
`

const SInnerContent = styled.div`
  margin: 22px 16px;
  display: flex;
  position: relative;
  justify-content: space-between;
`

const SFirstStep = styled.span`
  position: absolute;
  left: 0;
  align-self: center;
  background: #B7C6DA;
  border-radius: 100px;
  width: 8.2px;
  height: 8.2px;
`

const SLine = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%) translateY(-50%);
  width: calc(100% - 32px);
  margin-left: auto;
  margin-right: auto;
  height: 2px;
  background: #CDD9EA;
  z-index: 0;
`
